<template>
  <div class="wrap">
    <!-- 列表 -->
    <div class="special">
      <van-cell is-link @click="gotoUrl('/village/community')"
        ><img src="../../assets/shequ.png" class="linkicon" />我的社区</van-cell
      >
      <van-cell is-link @click="gotoUrl('/village/region')"
        ><img
          src="../../assets/xiaoqu.png"
          class="linkicon"
        />我的小区</van-cell
      >
      <van-cell is-link  @click="gotoUrl('/village/hygiene')"
        ><img
          src="../../assets/yiliaoweisheng.png"
          class="linkicon"
        />卫生医疗</van-cell
      >
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";

export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    //跳转到公共页面
    gotoUrl(path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style>
.wrap {
  background: #f7f8fa;
  min-height: 100%;
}
.special {
  padding: 10px 10px;
}
.special img {
  position: relative;
  top: 30%;
  margin-top: -10px;
  width: 24px;
  height: 24px;
}
</style>